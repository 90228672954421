import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { slideIn } from "../utils/motion";
import { FaTelegram } from "react-icons/fa";
import { AiFillTwitterCircle, AiOutlineCopy } from "react-icons/ai"
import MarqueeImage from "../utils/MarqueeImage";
import CA from "../utils/Ca";

import AOS from 'aos';
import 'aos/dist/aos.css'

const About = () => {
  const imageCount = 6;
  const images = [];
  for (let i = 1; i <= imageCount; i++) {
    images.push(`../img/pic-${i}.jpg`);
  }



  AOS.init({ once: true });



  const [canPlay, setCanPlay] = useState(false);

  return (<div className="w-full pt-5 text-[#f2f2f2] relative  text-center mt-5 ">
    <div className="px-4 md:px-10 flex flex-col items-center justify-center">
                <h1 className="text-[35px] sm:text-[45px] md:text-[75px] leading-tight  text-[#f2f2f2]"><span className="font-bold"> </span>$FOMO</h1>
     
      <h1 className=" leading-tight text-[30px] uppercase text-[#f2f2f2]">Contract Not released yet</h1>
      <img src="../img/pic-6.jpg" className="" alt="fomos" />

    </div>

    <div className=" hidden relative w-full">
      <img
        className="absolute top-1/2 left-full transform -translate-y-1/2 animate-slide"
        src="../img/logo.jpg"
        alt="Moving Image"
      />
    </div>
    <div className="w-full p-4 mx-auto md:w-[80%]  md:text-[24px] text-[18px]">

    </div>
    <div  className="  w-full grid grid-cols-2 md:grid-cols-3">
      {images.map((image, index) => (
        <img key={index} src={image} alt={`pic-${index + 1}`} />
      ))}
    </div>
    <div className="w-full text-[20px] bg-[#024205] text-[#fff] px-3 py-5 mt-5">
      All rights Reserved, FOMO | <a href="https://twitter.com/#">X</a> | <a href="https://t.me/ihavefomo">Telegram</a>
    </div>

  </div>);
}

export default About;