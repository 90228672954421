import { useState, useRef } from "react";
import { motion } from 'framer-motion';
import { loaderVariants, slideIn } from "../utils/motion";
import { FaArrowRight, FaTelegram, FaTwitter } from "react-icons/fa";

import { AiFillTwitterCircle, AiOutlineCopy, AiOutlineArrowRight, AiOutlineTwitter, AiOutlineRight, AiOutlineWallet } from "react-icons/ai"
import Button from "../utils/Button";
import AnimeLogo from "../utils/AnimeLogo";
import CA from "../utils/Ca";
import AOS from 'aos';
import 'aos/dist/aos.css'

const Hero = ({ pryColor
    , secColor }
) => {

    const socialData = [
        {
            imgUrl: "svg-1",
            link: 'https://t.me/telegram'
        },
        {
            imgUrl: "svg-4",
            link: 'https://uniswap.com'
        },
        {
            imgUrl: "svg-5",
            link: 'https://coinmarketcap.com'
        },
        {
            imgUrl: "svg-3",
            link: 'https://twitter.com'
        },

    ]

    AOS.init({ once: true });
    const [isCopied, setIsCopied] = useState(false);
    const handleCopied = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000)
    }

    const contractRef = useRef();

    const copyContractText = () => {
        const text = contractRef.current.textContent;
        navigator.clipboard.writeText(text);
        console.log('Text copied: ' + text);
        handleCopied();
    };


    return <div className=" text-[#fff] items-center  text-center  w-[100vw] flex flex-col gap-2 ">

        <div className="w-full">
            <iframe width="100%" height="600" src="https://www.youtube.com/embed/TRXdxiot5JM?si=P7pHQzudRhR7gGfz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>


    </div>;
}

export default Hero;