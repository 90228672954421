const Footer = () => {
    const socialData = [
        {
            imgUrl: "social-1",
            link: 'https://t.me/telegram'
        },
        {
            imgUrl: "social-4",
            link: 'https://uniswap.com'
        },
        {
            imgUrl: "social-5",
            link: 'https://coinmarketcap.com'
        },
        {
            imgUrl: "social-2",
            link: 'https://coingecko.com'
        },
        {
            imgUrl: "social-3",
            link: 'https://twitter.com'
        },

    ]
    return (<div className="w-full bg-[#EB9B13]" >
       
        <div className="w-[90%] md:w-[80%] mx-auto py-5 px-4 items-start justify-center gap-2 flex flex-col text-center text-[#fff] font-light text-[12px]">
        <p className="my-5 ">© 2023 PÈPÈ</p>

        </div>
        
    </div>);
}

export default Footer;